import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { getLink, Image } from "../../utils/helper";
import { validation } from "../../utils/validation";
import "./demo.scss";

const Demo = ({ story }) => {
  const { content: Content } = story;
  const [errors, setErrors] = useState([]);
  const {
    title,
    description,
    Form,
    footer_description,
    End_Title,
    End_Description,
    Company_Logos,
  } = Content;
  const initialState = {
    name: "",
    email: "",
    company: "",
    job_title: "",
  };
  const [formData, setFormData] = useState(initialState);
  const changeHandler = (e) => {
    const field = e.target.id;
    if (Object.keys(formData).includes(field)) {
      let updated_form = { ...formData, [field]: e.target.value };
      setFormData(updated_form);
    }
  };
  const validate = () => {
    let new_errors = [];
    Object.keys(formData).forEach((key) => {
      if (![Form[0]?.hidden_fields?.map(({key, value})=>key)].includes(key) && validation.isEmpty(formData[key])) {
        new_errors.push(key);
      }
    });
    setErrors(new_errors);
    return new_errors.length == 0;
  };
  const clickHandler = () => {
    if (validate() && window.analytics) {
      const data = formData
      Form[0]?.hidden_fields?.forEach(({key, value})=>(Object.assign(data , { [key] : value})))
      window.analytics.track("Leads", formData);
      navigate(getLink(Form[0]?.submit_btn[0]?.Link?.cached_url));
    }
    return;
  };
  return (
    <div className="demo-section">
      <div className="container">
        <div className="demo-inner-section">
          <h3> {title} </h3>
          <p> {description} </p>
          { Form?.map((item) => {
            const {
              first_name,
              job_description,
              work_email,
              company_name,
              submit_btn,
              hidden_fields
            } = item;
            return (
              <div className="demo-form">
                <input
                  type="text"
                  id="name"
                  placeholder={first_name}
                  onChange={changeHandler}
                  className={errors.includes("name") ? "error" : ""}
                />
                <input
                  type="text"
                  id="email"
                  placeholder={work_email}
                  onChange={changeHandler}
                  className={errors.includes("email") ? "error" : ""}
                />
                <input
                  type="text"
                  id="company"
                  placeholder={company_name}
                  onChange={changeHandler}
                  className={errors.includes("company") ? "error" : ""}
                />
                <input
                  type="text"
                  id="job_title"
                  placeholder={job_description}
                  onChange={changeHandler}
                  className={errors.includes("job_title") ? "error" : ""}
                />
                {
                  hidden_fields?.map(({key , value , _uid})=>{
                    return <input type="hidden" value={value} id={key} name={key}/>
                  })
                }
                {submit_btn?.map((item) => {
                  const { Label, Icon, Link: link } = item;
                  return (
                    <button onClick={clickHandler}>
                      {Label} <i src={Icon?.filename} />
                    </button>
                  );
                })}
                <p> {footer_description} </p>
              </div>
            );
          })}

          <div className="trusted-team">
            <h6> {End_Title} </h6>
            <div className="trusted-logo-container">
              {Company_Logos?.map((item) => (
                <Image src={item?.Image?.filename} alt={item?.Image?.alt} />
              ))}
            </div>
            <p> {End_Description} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
